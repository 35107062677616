import styled from 'styled-components';

export const Container = styled.header`
  padding: 0 20px;
  border-bottom: 2px solid #199dc4;
`;

export const AreaLogoMenu = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AreaTitulos = styled.header`
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  @media(max-width: 600px) {
    align-items: center;
    }
`;
