import { shade } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const TextoMenu = styled(Link)`
  color: #999999;
  font-size: 14px;
  text-decoration: none;

  &:hover{
    color: ${shade(0.3, '#999999')}
  }
`;
