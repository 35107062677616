import styled from 'styled-components';

export const Container = styled.h1`
  color: #13afe0;
  font-family: 'Arvo', serif;

  @media(max-width: 450px) {
    font-size: 25px;
    }
`;
