import React from 'react';
import IAPI from '../../../models/IAPI';
import TextoAPI from '../../TextoAPI';
import TituloAPI from '../../TituloAPI';
import IconeProjeto from '../IconeProjeto';

import { Container, AreaTitulo, Ancora } from './styles';

interface ItemAPIProps {
    api: IAPI;
}

const ItemAPI: React.FC<ItemAPIProps> = ({ api }) => {
    return (
        <Container>
            <AreaTitulo>
                <IconeProjeto nomeImagem={api.nomeImagem}/>
                <TituloAPI>{api.nome}</TituloAPI>
            </AreaTitulo>
            <TextoAPI>{api.descricaoComercial}</TextoAPI>
            <Ancora href={api.url} target="_blank">Documentação</Ancora>
        </Container>
    );
}

export default ItemAPI;