import React, { useEffect, useState } from 'react';
import IAPI from '../../models/IAPI';
import ServiceAPIs from '../../services/ServiceAPIs';
import InputPesquisa from '../InputPesquisa';
import Titulo from '../Titulo';
import ItemAPI from './ItemAPI';

import { Container, CabecalhoPesquisa, AreaTitulo, AreaAPIs } from './styles';

const servicoApis = new ServiceAPIs();

const ListaAPIs: React.FC = () => {

    const [pesquisa, setPesquisa] = useState('');
    const [APIs, setAPIs] = useState<IAPI[]>([]);
    const [APIsFiltradas, setAPIsFiltradas] = useState<IAPI[]>([]);


    useEffect(() => {
        const obterApis = async () => {

            if(pesquisa.length > 0){
                
                if(pesquisa.length > 2){
                    const { sucesso, conteudo } = await servicoApis.PesquisarAPIs(pesquisa);
                    if (sucesso) {
                        setAPIs(conteudo);
                    }
                }
    
                return;
            }
    
            const { sucesso, conteudo } = await servicoApis.ObterAPIs();
            if (sucesso) {
                setAPIs(conteudo);
            }
        }
        obterApis();
    }, [pesquisa]);

    useEffect(() => {
        setAPIsFiltradas(APIs.filter(a => a.comercial));
    }, [APIs]);

    return (
        <Container>
            <CabecalhoPesquisa>
                <InputPesquisa value={pesquisa} onChange={(e) => setPesquisa(e.target.value)} />
            </CabecalhoPesquisa>
            <AreaTitulo>
                <Titulo>Referências das APIS:</Titulo>
            </AreaTitulo>
            <AreaAPIs>
                {APIsFiltradas.map(api => <ItemAPI key={api.id} api={api} />)}
            </AreaAPIs>
        </Container>
    );
}

export default ListaAPIs;