import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const AreaTitulo = styled.div`
  border-bottom: 1px solid #ddd;
`;

export const Ancora = styled.a`
text-decoration: none;
color: #199dc4;
`;
