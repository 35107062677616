import React from 'react';
import LogoRGBsys from '../LogoRGBsys';
import Menu from '../Menu';
import SubTitulo from '../SubTitulo';
import TituloSite from '../TituloSite';

import { Container, AreaLogoMenu, AreaTitulos } from './styles';

const Cabecalho: React.FC = () => {
    return (
        <Container>
            <AreaLogoMenu>
                <LogoRGBsys />
                <Menu />
            </AreaLogoMenu>
            <AreaTitulos>
                <TituloSite>Portal de Integração RGBsys {'{ }'}</TituloSite>
                <SubTitulo>Conheça todas as nossas APIs e as suas funcionalidades.</SubTitulo>
            </AreaTitulos>
        </Container>
    );
}

export default Cabecalho;