import styled from 'styled-components';

export const Container = styled.div`

`;

export const CabecalhoPesquisa = styled.div`
  background-color: #F6F7F8;
  border-bottom: 1px solid #dadee2;
  display: flex;
  justify-content: flex-end;
  padding: 10px;

  @media(max-width: 600px) {
    justify-content: center;
    }
`;

export const AreaTitulo = styled.div`
padding: 20px;
`;

export const AreaAPIs = styled.div`
    margin: 40px 0;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 100px;

    @media(max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media(max-width: 750px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;
