import React from 'react';

import { Container, TextoMenu } from './styles';

const Menu: React.FC = () => {
  return (
      <Container>
          <TextoMenu to='contato'>Contato</TextoMenu>
          <TextoMenu to='http://rgbsys.com.br'>Site RGBsys</TextoMenu>
      </Container>
  );
}

export default Menu;