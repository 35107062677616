import React from 'react';
import LogoRGBsys from '../LogoRGBsys';

import { Container } from './styles';

const Rodape: React.FC = () => {
    return (
        <Container>
            <LogoRGBsys />
        </Container>
    );
}

export default Rodape;